.shadow-sm {
    box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important;
}
#nav-bar{
      background: #ebeaf7;
          padding-left: 30px;
}
@media(max-width:768px){
  .card-signin.login-card {
    width: 100% !important;
  }
  #nav-bar{
    overflow-x: auto;
  }
  .navbar-collapse {
    display: block !important;
  }
}
.cursor-pointer{
  cursor: pointer;
}
#tabBar-link a {
  color: #2b8edf !important;
}
.shadow-nav {
  box-shadow: 0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12);
}
.main-nav{
  background: linear-gradient(to left, #385bfd, #7145fc 34%);
  color: white;
}
.main-nav li , .main-nav li:hover {

   border-bottom: none !important ;
   padding-left: 20px !important;
}
.main-nav a{
  
  color: white !important;

}
.ant-layout , .ant-layout-footer{
  background: #f6f6f6fa;
}
.ant-menu-submenu{
  float: right !important;
}

 .ant-menu-submenu-title:hover {
   color:  white !important;
}

.ant-btn-primary {
    color: #fff;
    background: #395bfd;
    border-color: #395bfd;
}
.ant-btn-primary:hover ,.ant-btn-primary:active {
   color: #fff;
    background: #395bfd;
    border-color: #395bfd;
   box-shadow: 0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12);
}
.anticon {
  font-size: larger;
}
.anticon svg {
      margin-top: -5px;
}
.profileTable .anticon svg {
  margin-top: -2px;
}
.ant-pagination{
  margin-right: 10px;
}
.text-color {
  color: #001577 !important;
}
.ant-table-thead > tr > th {
     background: white !important; 
}
.step-card {
  border-radius: 4px;
  box-shadow: 0 7px 13px 0 rgba(0, 0, 0, 0.06);
 
   
}
.step-card-aud {
  background-color: white;

   
}
.step-card-aud-head{
  
    box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important;
   
}

.step-card:hover {
   box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important;
}
.ant-tree {
  background: #f6f6f657;
   padding: 5px;

   }

 .heading{
    width: 405px;
 font-size: 34px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  margin-bottom: 10px;
 }  
 .role-desc {
  width: 405px;
  height: 28px;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;

}
.role-box {
  width: 72%;
  height: 274px;
  border-radius: 4px;
  background-color: rgba(113, 69, 252, 0.05);
  padding: 21px;
 
}
.role {
  width: 483px;
  height: 57px;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4e4e4e;
  margin-top: 20px;
}
.role .name {
  font-weight: bold;
}
.custom-clr {
  color: #001577;
}
.downloadBtn > span{
  float: right;
    padding-left: 10px;
    margin-top: 1px;

}
.action {
 
}
.action a {
  font-size: 13px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4e4e4e;
  padding-right: 20px;
}

.ant-table-thead > tr > th , .ant-table-tbody > tr > td {
   
    border-right: none !important;
}
.ant-steps-item-wait{
  opacity: 0.4;
}

.ant-btn-primary {
    text-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
}
.ant-spin-container {

     background: white;

}
.ant-table.ant-table-bordered > .ant-table-container {
  border: none;
}
.ant-steps-item-process .ant-steps-item-icon {
    background: #5c92e0;
}
.ant-steps-item-title{color: rgb(82 146 224) !important;font-size: 11px;}
.ant-steps-item-wait .ant-steps-item-title {color: rgba(0, 0, 0, 0.45) !important}
.row-start {
  margin: 0px -24px
}
.row-start .ant-card-head {
  border: none !important;
}
.row-start .ant-card-body{
 padding-top: 0px;
}
.border-tb{
      border-top: 1px solid #f0f0f0;
    border-bottom: 1px solid #f0f0f0;
}
 .ant-slider-handle {
  border: solid 2px #395bfd !important;
}
 .ant-slider-dot-active {
    border-color: #395bfd;
}
 .ant-slider-track {
    background-color: #395bfd !important;
}
.row-start .ant-card-body {
  padding-top: 0px !important;
}

.active-nav {
  border-bottom: #395bfd 2px solid !important;
}
.row-spacer .ant-table-cell {
    border-bottom: 8px solid;
    border-color: #f6f6f6;
}
.font-size-12 {
  font-size: 12px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4e4e4e;
}
.audiencetable .ant-table-cell {
  padding: 6px 10px !important
}
.audience .customSelect .MuiOutlinedInput-root {
  height: 50px;
}
.audienceDetailtable .ant-table-cell {
  padding: 6px 10px !important
}
.audienceDetailtable .account{
  font-size: 12px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  letter-spacing: 0.08px;
}
.audienceDetailtable .clr {
   color: #4e4e4e;
}
.downloadCsv {
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.12) !important;
  border: solid 1px #979797 !important;
  background-color: rgba(255, 255, 255, 0.09) !important;
  font-size: 12px !important;
  font-weight: 400;
  color: #4e4e4e !important;
}
.sidebar-ico-text {
  width: 185px;
  height: 24px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.15px;
  color: #4e4e4e;
  margin-left: 10px;
}
 .sidebar-icon{
   font-size: x-small;
    color: #4e4e4e;
 }
 .errStr{
   color: #c20c1c;
 }
 .ant-spin-container {
   background: initial !important;
 }
 .firmograph .ant-select-multiple .ant-select-selector {
   border:none;
   border-bottom: 1px solid rgba(0, 0, 0, 0.42);
   border-radius: 0;
   padding: 0px;
   padding-bottom: 5px;
 }
.firmograph .ant-select-selection-placeholder {
      /* color: aliceblue; */
      color: rgba(0, 0, 0, 0.54);
      padding: 0;
      font-size: 1rem;
      font-family: "Roboto",
      "Helvetica",
      "Arial",
      sans-serif;
      font-weight: 400;
      line-height: 1;
      letter-spacing: 0.00938em;
      margin-left: -10px;
}
.firmograph .ant-select-focused:not(.ant-select-disabled).ant-select-multiple .ant-select-selector {
  box-shadow: none;
  border-color:none;
  
}
.firmograph .ant-select:not(.ant-select-disabled):hover .ant-select-selector{
   border-color:initial;
}
 
  /* .ant-slider-tooltip {
   display: none !important;
 }
 .ant-slider-handle:hover ~.ant-slider-tooltip {
    display: block !important;
 } */
 #sliderHide {
   max-height: 0%;
   transition: 0.5s;
   overflow: hidden;
 }
 .sidebarFilter .ant-slider-rail {
   background-color: #e1e1e1 !important;
 }
 
 .bd-callout {
   padding: .5rem;
   margin-top: 1rem;
   margin-bottom: 1rem;
   border: 1px solid #e9ecef;
   border-left-width: .25rem;
   border-radius: .25rem;
 }
 .bd-callout-info {
   border-left-color: #001577;
 }
